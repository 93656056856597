import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';

import { AuthService } from '@emrm/core/auth/service';

export const AuthGuard: CanActivateFn = () => {
	const router: Router = inject(Router);
	const authService: AuthService = inject(AuthService);

	if (!authService.isLoggedIn()) {
		router.navigate(['/auth']);

		return false;
	}

	return true;
};
